export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53')
];

export const server_loads = [0];

export const dictionary = {
		"/(public)": [47,[7]],
		"/(public)/access": [48,[7,8]],
		"/(public)/access/verify": [49,[7,8]],
		"/(app)/account": [21,[2]],
		"/(app)/account/device": [22,[2]],
		"/(public)/auth/email/verify": [50,[7]],
		"/(public)/auth/forgot-password": [51,[7]],
		"/(public)/auth/reset-password": [52,[7]],
		"/(app)/challenges": [23,[2,3]],
		"/(app)/challenges/event/join/[groupCode]": [28,[2,3]],
		"/(app)/challenges/event/join/[groupCode]/[teamCode]": [29,[2,3]],
		"/(app)/challenges/event/[groupKey]": [25,[2,3]],
		"/(app)/challenges/event/[groupKey]/info": [26,[2,3]],
		"/(app)/challenges/event/[groupKey]/leaderboard": [27,[2,3]],
		"/(app)/challenges/series": [30,[2,3]],
		"/(app)/challenges/[challengeKey]": [24,[2,3]],
		"/(app)/contact": [31,[2]],
		"/(app)/dashboard": [32,[2]],
		"/(app)/harbor": [33,[2,4]],
		"/(app)/harbor/datasets": [34,[2,4]],
		"/(app)/harbor/datasets/[datasetsKey]": [35,[2,4]],
		"/(app)/harbor/models": [36,[2,4]],
		"/(app)/harbor/models/[modelsKey]": [37,[2,4]],
		"/(public)/redirect": [53,[7]],
		"/(app)/spyglass/projects": [38,[2,5]],
		"/(app)/spyglass/projects/[projectKey]": [39,[2,5]],
		"/(app)/spyglass/signing": [40,[2,5]],
		"/(app)/spyglass/targets": [41,[2,5]],
		"/(app)/strikes": [42,[2,6]],
		"/(app)/strikes/agents": [44,[2,6]],
		"/(app)/strikes/agents/[agentKey]/runs/[runId]": [45,[2,6]],
		"/(app)/strikes/overview": [46,[2,6]],
		"/(app)/strikes/[strikeKey]": [43,[2,6]],
		"/(app)/[sandbox=dev]": [9,[2]],
		"/(app)/[sandbox=dev]/stories": [10,[2]],
		"/(app)/[sandbox=dev]/stories/avatar": [11,[2]],
		"/(app)/[sandbox=dev]/stories/button": [12,[2]],
		"/(app)/[sandbox=dev]/stories/code-block": [13,[2]],
		"/(app)/[sandbox=dev]/stories/colors": [14,[2]],
		"/(app)/[sandbox=dev]/stories/drawer": [15,[2]],
		"/(app)/[sandbox=dev]/stories/item-dropdown": [16,[2]],
		"/(app)/[sandbox=dev]/stories/json-table": [17,[2]],
		"/(app)/[sandbox=dev]/stories/panel-tab": [18,[2]],
		"/(app)/[sandbox=dev]/stories/pill": [19,[2]],
		"/(app)/[sandbox=dev]/stories/text-input": [20,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';